'use client';

import React from 'react';
import { ErrorPage } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';

type NotFoundPageProps = {
  action?: boolean;
};

export default function NotFoundPage({ action = true }: NotFoundPageProps): React.JSX.Element {
  const { t } = useClientTranslations('common');

  return (
    <ErrorPage
      title={t('errors.not-found.title')}
      code="404"
      message={t('errors.not-found.description')}
      action={action ? { title: t('errors.not-found.cta'), url: '/' } : undefined}
    />
  );
}
