import { cn } from '@utils';
import { Column, Table } from '@tanstack/react-table';
import { ArrowDown, ArrowUp, ArrowUpDown } from 'lucide-react';
import { Button, ButtonProps } from '../button/button';

export type SortButtonProps<Data> = {
  table: Table<Data>;
  column: Column<Data>;
  className?: ButtonProps['className'];
  children: React.ReactNode;
};

export function SortButton<Data>({ table, column, className, children }: SortButtonProps<Data>): React.JSX.Element {
  const sortDirection = column.getIsSorted();
  let Icon = ArrowUpDown;
  if (sortDirection === 'asc') {
    Icon = ArrowUp;
  } else if (sortDirection === 'desc') {
    Icon = ArrowDown;
  }
  return (
    <Button
      disabled={table.getRowCount() === 0}
      className={cn('px-xxs -mx-xxs !py-xxs font-normal', className)}
      variant="quaternary"
      onClick={() => column.toggleSorting(sortDirection === 'asc')}
      RightIcon={Icon}
    >
      {children}
    </Button>
  );
}
