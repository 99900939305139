'use client';

import { PropsWithChildren, useEffect, useState } from 'react';
import Script from 'next/script';
import { AxeptioService } from '../services/axeptio.service';
import { TermsDocumentType } from '../types/terms-document';
import { Configuration } from '../configuration';
import { TermsContext } from './terms.context';

export const TermsProvider = ({
  children,
  locale,
  configuration,
}: PropsWithChildren<{ locale: string; configuration: Configuration }>): React.JSX.Element => {
  const [isReady, setIsReady] = useState(false);
  const [sdk, setSdk] = useState<AxeptioService | null>(null);

  useEffect(() => {
    setSdk(
      new AxeptioService(
        {
          onSdkReady: (): void => setIsReady(true),
        },
        configuration,
      ),
    );
  }, [configuration]);

  const openDocumentForReading = (document: TermsDocumentType, campus?: string): void => {
    sdk?.openDocumentForReading({ type: document, locale, campus });
  };

  const openDocumentsForConsent = (documents: TermsDocumentType[], signerId: string, campus?: string): void => {
    sdk?.openDocumentsForConsent(
      documents.map((type) => ({ type, locale, campus })),
      signerId,
    );
  };

  return (
    <TermsContext.Provider
      value={{
        isReady,
        openDocumentForReading,
        openDocumentsForConsent,
      }}
    >
      {children}
      <Script src="//static.axept.io/sdk-slim.js"></Script>
    </TermsContext.Provider>
  );
};
