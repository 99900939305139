import React from 'react';

const radius = 85;
const strokeWidth = 30;

/**
 * Convert polar coordinates to cartesian
 * @param r distance from origin
 * @param a clockwise angle relative to the top direction
 * @returns a string `x y` representing the position of the vertex in the viewbox
 */
const p2c = (r: number, a: number): string =>
  `${r * Math.sin((a * Math.PI) / 180)} ${-r * Math.cos((a * Math.PI) / 180)}`;

/**
 * Svg path of an arc of a given radius from the current position to a new angular position
 * The angle of the arc must be 180 degrees maximum
 * @param r radius of the arc
 * @param clockwise true if rotating clockwise, false if counter-clockwise
 * @param endAngle the angle at the end of the arc
 * @returns svg path of the arc
 */
const shortArc = (r: number, clockwise: boolean, endAngle: number): string =>
  `A${r} ${r} 0 0 ${clockwise ? 1 : 0} ${p2c(r, endAngle)} `;

export type FancyCircularProgressProps = {
  progress: number; // progress in percentage (values greater than 100% are supported)
};

export const FancyCircularProgress = ({ progress }: FancyCircularProgressProps): React.JSX.Element => {
  const angle = (progress / 100) * 360; // angle in degrees
  const innerRadius = radius - strokeWidth / 2;
  const outerRadius = radius + strokeWidth / 2;
  const width = 2 * outerRadius;
  const chevronWidth = 10;

  const thickArc = (toAngle: number): string => {
    // Split the angle into chunks of maximum 180 degrees (because the svg arcs must cover less than 360 degrees)
    // example: if toAngle is 1337 then angularSteps is [0, 180, 360, 540, 720, 900, 1080, 1338]
    const angularSteps: number[] = [];
    for (let i = 0; i < toAngle / 180; i++) {
      angularSteps.push(i * 180);
    }
    if (toAngle > angularSteps[angularSteps.length - 1]) {
      angularSteps.push(toAngle);
    }

    // First end
    let path = `M${p2c(innerRadius, 0)} L${p2c(outerRadius, 0)} `;

    // Draw outer arc by rotating clockwise
    angularSteps.forEach((angularStep) => (path += shortArc(outerRadius, true, angularStep)));

    // Second end
    path += `L${p2c(innerRadius, toAngle)} `;

    // Draw inner arc by rotating counter-clockwise
    angularSteps
      .reverse()
      .slice(1)
      .forEach((angularStep) => (path += shortArc(innerRadius, false, angularStep)));

    return path;
  };

  const chevronTip = p2c(radius, angle + 2.2);
  const chevronA = p2c(radius - chevronWidth / 2, angle);
  const chevronB = p2c(radius + chevronWidth / 2, angle);

  const gradientAngle = angle % 360;
  const dropshadowX = 3 * Math.cos((angle * Math.PI) / 180);
  const dropshadowY = 3 * Math.sin((angle * Math.PI) / 180);

  return (
    <svg
      version="1.0"
      viewBox={`${-outerRadius} ${-outerRadius} ${width} ${width}`}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
    >
      <style>{`
        .svg-shadow {
          -webkit-filter: drop-shadow( ${dropshadowX}px ${dropshadowY}px 2px rgba(0, 0, 0, .3));
          filter: drop-shadow( ${dropshadowX}px ${dropshadowY}px 2px rgba(0, 0, 0, .3));
        }
        .conic-gradient {
          background: conic-gradient(from ${gradientAngle}deg, var(--accent-gradient-from), var(--accent-gradient-to));
        }
      `}</style>
      <defs>
        <linearGradient id="back-gradient">
          <stop offset="0%" stopColor="var(--secondary-accent-gradient-from)" />
          <stop offset="47%" stopColor="var(--secondary-accent-gradient-via)" />
          <stop offset="100%" stopColor="var(--secondary-accent-gradient-to)" />
        </linearGradient>
      </defs>
      <path d={thickArc(360)} stroke="none" fill="url(#back-gradient)" opacity="20%" />
      {angle > 0 ? (
        <>
          <clipPath id="clip">
            <path d={thickArc(angle)} stroke="none" />
          </clipPath>
          <foreignObject x={-outerRadius} y={-outerRadius} width={width} height={width} clipPath="url(#clip)">
            <div className="conic-gradient h-full w-full" />
          </foreignObject>
          <path
            d={`M${p2c(innerRadius, angle - 1)} L${p2c(outerRadius, angle - 1)} A${strokeWidth / 2} ${strokeWidth / 2} 0 0 1 ${p2c(innerRadius, angle - 1)}`}
            stroke="none"
            fill="var(--accent-gradient-to)"
            className="svg-shadow"
          />
          <path d={`M${chevronA} L${chevronTip} L${chevronB}`} stroke="currentColor" fill="none" strokeWidth="1.5" />
        </>
      ) : null}
    </svg>
  );
};
FancyCircularProgress.displayName = 'Fancy circular progress';
