'use client';

import React from 'react';
import { useClientTranslations } from '@core-systems/i18n';
import { ErrorPage } from '@design-system';

export function TechnicalErrorPage(): React.JSX.Element {
  const { t } = useClientTranslations('admin');
  return (
    <ErrorPage
      title={t('errors.default.title')}
      code="500"
      message={t('errors.default.description')}
      action={{ title: t('errors.default.cta'), url: '/' }}
    />
  );
}
