'use client';

import { useTextReveal } from '../../hooks';

export type TextRevealProps = React.HTMLAttributes<HTMLSpanElement> & { text: string };
const TextReveal = ({ className, text, ...props }: TextRevealProps): React.JSX.Element => {
  const revealedText = useTextReveal(text);

  return (
    <span className={className} {...props}>
      {revealedText}
    </span>
  );
};

export { TextReveal };
