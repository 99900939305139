'use client';

import NextError from 'next/error';

export default function GlobalError(): React.JSX.Element {
  return (
    <html>
      <body>
        <NextError statusCode={undefined as any} />
      </body>
    </html>
  );
}
