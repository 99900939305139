'use client';

import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';
import { defaultTimeZone, getMessageFallback, onError } from '../services/i18n.services';

export function TranslationsProvider({
  children,
  locale,
  messages,
}: {
  children: React.ReactNode;
  locale: string;
  messages: AbstractIntlMessages;
}): React.JSX.Element {
  return (
    <NextIntlClientProvider
      locale={locale}
      messages={messages}
      timeZone={defaultTimeZone}
      onError={onError}
      getMessageFallback={getMessageFallback}
    >
      {children}
    </NextIntlClientProvider>
  );
}
