'use client';

import { useLocale, useTranslations } from 'next-intl';
import { customTranslate, escapeKey } from '../services/i18n.services';

export type TranslationFunction = ReturnType<typeof useTranslations>;

export const useClientTranslations = (namespace?: string): { t: TranslationFunction; locale: string } => {
  const t = useTranslations(namespace ? escapeKey(namespace) : undefined);
  const locale = useLocale();

  return { t: Object.assign(customTranslate(t), t), locale };
};
