'use client';

import { createContext, PropsWithChildren, useContext } from 'react';

class ContextConsumptionOutsideOfProviderError extends Error {
  constructor() {
    super('Missing environment configuration context provider');
  }
}

export type EnvConfig = Record<string, any>;

const ClientSideEnvContext = createContext<EnvConfig>({});

export const ClientSideEnvProvider = ({ env, children }: PropsWithChildren<{ env: EnvConfig }>): React.JSX.Element => {
  return <ClientSideEnvContext.Provider value={env}>{children}</ClientSideEnvContext.Provider>;
};

export const useClientSideEnv = (): EnvConfig => {
  const env = useContext(ClientSideEnvContext);

  if (env === undefined) {
    throw new ContextConsumptionOutsideOfProviderError();
  }

  return env;
};
