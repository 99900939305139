export enum Tabs {
  /**
   * Tabs related to Campus administration
   */
  CAMPUSES = 'CAMPUSES',
  STAFFS = 'STAFFS',

  /**
   * TODO: tabs from apply to migrate to campus administration
   */
  CAMPUS_SETTINGS = 'CAMPUS_SETTINGS',
  HUBS = 'HUBS',

  /**
   * Tabs related to apply
   */
  MEETINGS = 'MEETINGS',
  PATHS = 'PATHS',
  PISCINES = 'PISCINES',
  APPLICANTS = 'APPLICANTS',

  /**
   * Tabs related to learning
   */
  RUSHES = 'RUSHES',
  REVIEWS = 'REVIEWS',
  EXAMS = 'EXAMS',
}

export type Menu = {
  tabs: Tabs[];
};
