export const dynamic = 'force-dynamic';

import { TranslationValues, Formats, IntlError } from 'next-intl';
import { TranslationFunction } from '../client/use-translation.hook';
import getI18nConfiguration from '../configuration';

export type SupportedTranslation = { key: string; name: string; isDefault: boolean; icon?: string };

export const supportedTranslations: SupportedTranslation[] = getI18nConfiguration().languages;

export const supportedLocales: SupportedTranslation['key'][] = supportedTranslations.map(
  (translation) => translation.key,
);

export const getBackendUrl = (): string => {
  const { cdnUri } = getI18nConfiguration();
  return `${cdnUri}/{{lng}}/{{ns}}`;
};

export const escapeKey = (key: string): string => key.replace(/\./g, '_');
export const unEscapeKey = (key: string): string => key.replace(/\_/g, '.');

export const customTranslate =
  (t: TranslationFunction) =>
  (key: string, values?: TranslationValues, formats?: Formats): string => {
    const escapedKey = escapeKey(key);
    const keyWithNamespace = values?.ns ? `${escapeKey(values.ns.toString())}.${escapedKey}` : escapedKey;
    return t(keyWithNamespace, values, formats);
  };

export const onError = (error: IntlError): void => {
  return; // Suppress error in production
};

export const getMessageFallback = (info: { error: IntlError; key: string; namespace?: string }): string => {
  return unEscapeKey([info.namespace, info.key].join('.'));
};

export const defaultTimeZone = 'Europe/Paris';
