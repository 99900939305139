import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.1.0_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@19._zfrnzarkr3r3qpe6l7u3yttk5y/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.1.0_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@19._zfrnzarkr3r3qpe6l7u3yttk5y/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.1.0_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@19._zfrnzarkr3r3qpe6l7u3yttk5y/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.1.0_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@19._zfrnzarkr3r3qpe6l7u3yttk5y/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.1.0_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@19._zfrnzarkr3r3qpe6l7u3yttk5y/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.1.0_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@19._zfrnzarkr3r3qpe6l7u3yttk5y/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.1.0_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@19._zfrnzarkr3r3qpe6l7u3yttk5y/node_modules/next/dist/lib/metadata/metadata-boundary.js");
