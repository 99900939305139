'use client';
import * as React from 'react';
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  TableOptions,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { cn } from '@utils';
import { TablePagination } from './pagination';

const BaseTable = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => (
    <table ref={ref} className={cn('relative w-full caption-bottom', className)} {...props} />
  ),
);
BaseTable.displayName = 'BaseTable';

const BaseTableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <thead ref={ref} className={cn('border-card-border uppercase [&_tr]:border-b', className)} {...props} />
  ),
);
BaseTableHeader.displayName = 'BaseTableHeader';

const BaseTableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tbody ref={ref} className={cn('border-card-border [&_tr:last-child]:border-0', className)} {...props} />
  ),
);
BaseTableBody.displayName = 'BaseTableBody';

const BaseTableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tfoot ref={ref} className={cn('border-card-border border-t [&>tr]:last:border-b-0', className)} {...props} />
  ),
);
BaseTableFooter.displayName = 'BaseTableFooter';

const BaseTableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn(
        'hover:bg-secondary-background-transparent data-[state=selected]:bg-secondary-background-transparent border-card-border border-b transition-colors',
        className,
      )}
      {...props}
    />
  ),
);
BaseTableRow.displayName = 'BaseTableRow';

const BaseTableHead = React.forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <th
      ref={ref}
      className={cn(
        'px-md py-lg leading-sm text-left align-middle text-sm font-normal [&:has([role=checkbox])]:pr-0',
        className,
      )}
      {...props}
    />
  ),
);
BaseTableHead.displayName = 'BaseTableHead';

const BaseTableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <td ref={ref} className={cn('p-md align-middle [&:has([role=checkbox])]:pr-0', className)} {...props} />
  ),
);
BaseTableCell.displayName = 'BaseTableCell';

const BaseTableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(
  ({ className, ...props }, ref) => <caption ref={ref} className={cn('mt-md', className)} {...props} />,
);
BaseTableCaption.displayName = 'BaseTableCaption';

interface TableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  testid?: string;
  locale?: string;
  args?: Record<any, any>;
  noData?: React.JSX.Element;
  sorting?: {
    state: SortingState;
    onSortingChange: OnChangeFn<SortingState>;
  };
  pagination?: {
    rowsCount: number;
    state: PaginationState;
    onPaginationChange: OnChangeFn<PaginationState>;
    nextLabel?: string;
    previousLabel?: string;
  };
  className?: string;
}

export function Table<TData, TValue>({
  columns,
  data,
  testid,
  locale,
  args,
  noData,
  sorting,
  pagination,
  className,
}: TableProps<TData, TValue>): React.JSX.Element {
  const tableOptions: TableOptions<TData> & { state: { sorting?: SortingState; pagination?: PaginationState } } = {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: { args, locale },
    state: {},
  };

  if (sorting) {
    tableOptions.manualSorting = true;
    tableOptions.onSortingChange = sorting.onSortingChange;
    tableOptions.state.sorting = sorting.state;
  }

  if (pagination) {
    tableOptions.rowCount = pagination.rowsCount;
    tableOptions.manualPagination = true;
    tableOptions.onPaginationChange = pagination.onPaginationChange;
    tableOptions.state.pagination = pagination.state;
  }

  const table = useReactTable(tableOptions);

  return (
    <>
      <div
        className={cn(
          'border-card-border text-md leading-md bg-card-background overflow-x-auto rounded-sm border',
          className,
        )}
      >
        <BaseTable data-testid={testid}>
          <BaseTableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <BaseTableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <BaseTableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </BaseTableHead>
                  );
                })}
              </BaseTableRow>
            ))}
          </BaseTableHeader>
          <BaseTableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <BaseTableRow key={`row-${row.id}`} data-state={row.getIsSelected() ? 'selected' : undefined}>
                  {row.getVisibleCells().map((cell) => (
                    <BaseTableCell key={`cell-${cell.id}`}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </BaseTableCell>
                  ))}
                </BaseTableRow>
              ))
            ) : (
              <BaseTableRow>
                <BaseTableCell
                  colSpan={columns.length}
                  className="hover:bg-secondary-background-transparent h-24 text-center"
                >
                  {noData || <span>No results.</span>}
                </BaseTableCell>
              </BaseTableRow>
            )}
          </BaseTableBody>
        </BaseTable>
      </div>
      {Boolean(pagination) && Boolean(table.getRowModel().rows.length) && (
        <TablePagination table={table} nextLabel={pagination?.nextLabel} previousLabel={pagination?.previousLabel} />
      )}
    </>
  );
}
