import React from 'react';

export type DrawerDirection = 'top' | 'bottom' | 'left' | 'right';

export type DrawerContextValue = {
  direction: DrawerDirection;
};

export const drawerContext = React.createContext<DrawerContextValue>({ direction: 'bottom' });

export const DrawerContextProvider = ({
  children,
  direction,
}: {
  children: React.ReactNode;
  direction?: DrawerDirection;
}): React.JSX.Element => {
  return (
    <drawerContext.Provider value={{ direction: direction ? direction : 'bottom' }}>{children}</drawerContext.Provider>
  );
};

export const useDrawerContext = (): DrawerContextValue => {
  return React.useContext(drawerContext);
};
