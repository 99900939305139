'use client';

import * as React from 'react';
import { cn } from '@utils';

export type FancyProgressBarProps = { value?: number | null | undefined; className?: string };

export const FancyProgressBar = ({ value, className }: FancyProgressBarProps): React.JSX.Element => {
  const effectiveValue = Math.max(Math.min(value ?? 0, 100), 0);
  return (
    <div className={cn('relative w-full', className)}>
      <div
        className={cn(
          'from-secondary-accent-gradient-from via-secondary-accent-gradient-via to-secondary-accent-gradient-to bg-gradient-to-r',
          'absolute left-0 top-0 h-2 w-full rounded-md opacity-20',
        )}
      />
      <div
        className={cn(
          'from-accent-gradient-from to-accent-gradient-to bg-gradient-to-r',
          'absolute left-0 top-0 h-2 rounded-md',
        )}
        style={{ width: `${effectiveValue}%` }}
      />
    </div>
  );
};
FancyProgressBar.displayName = 'Fancy progress bar';
