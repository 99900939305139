'use client';
import { ChevronRight, GraduationCap, LayoutList, LucideIcon, NotebookPen, Rocket } from 'lucide-react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';
import { i18nNamespace } from '@admin/shared/i18n-namespaces';
import { Menu, Tabs } from '@admin/shared/types/menu';
import { useClientLocale, useClientTranslations } from '@core-systems/i18n';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  TruncatedTextWithTooltip,
} from '@design-system';

type Entry = {
  tab?: Tabs;
  key: string;
  subPath: string;
  icon?: LucideIcon;
};

type MenuEntry = {
  key: string;
  ns: string;
  path: string;
  icon?: LucideIcon;
  entries: Entry[];
};

const C_PISCINE_PROGRAM_SESSION_ID = 'be584900-ea1f-4821-a139-71bfc596bd8a';

const menuEntries: MenuEntry[] = [
  {
    key: 'campus-administration.menu.title',
    ns: 'admin',
    path: 'campus-administration',
    icon: LayoutList,
    entries: [
      {
        key: 'campus-administration.menu.entries.campuses',
        subPath: 'campuses',
        tab: Tabs.CAMPUSES,
      },
      {
        key: 'campus-administration.menu.entries.staff',
        subPath: 'staffs',
        tab: Tabs.STAFFS,
      },
    ],
  },
  {
    key: 'apply.menu.title',
    ns: 'admin',
    path: 'apply',
    icon: GraduationCap,
    entries: [
      {
        key: 'apply.menu.entry.paths.title',
        subPath: 'path-configurations',
        tab: Tabs.PATHS,
      },
      {
        key: 'apply.menu.entry.meetings.title',
        subPath: 'meetings',
        tab: Tabs.MEETINGS,
      },
      {
        key: 'apply.menu.entry.piscines.title',
        subPath: 'piscines',
        tab: Tabs.PISCINES,
      },
      {
        key: 'apply.menu.entry.applicants.title',
        subPath: 'search-applicants',
        tab: Tabs.APPLICANTS,
      },
      {
        key: 'apply.menu.entry.campuses.title',
        subPath: 'settings',
        tab: Tabs.CAMPUS_SETTINGS,
      },
      {
        key: 'apply.menu.entry.hubs.title',
        subPath: 'hubs',
        tab: Tabs.HUBS,
      },
    ],
  },
  // {
  //   key: 'entries.paperwork',
  //   ns: i18nNamespace.PAPERWORK_ADMIN_MENU,
  //   path: 'paperwork',
  //   icon: Paperclip,
  //   entries: [
  //     {
  //       key: 'entries.templates',
  //       icon: BookDashed,
  //       subPath: 'templates',
  //     },
  //     {
  //       key: 'entries.tracks',
  //       icon: Workflow,
  //       subPath: 'tracks',
  //     },
  //     {
  //       key: 'entries.sessions',
  //       icon: CalendarClock,
  //       subPath: 'sessions',
  //     },
  //     {
  //       key: 'entries.records',
  //       icon: FolderSearch,
  //       subPath: 'records',
  //     },
  //     {
  //       key: '[REMOVE ME] User component demo',
  //       icon: User,
  //       subPath: 'user-component-demo',
  //     },
  //   ],
  // },

  {
    key: 'learning.menu.title',
    ns: i18nNamespace.ADMIN,
    path: 'learning',
    icon: GraduationCap,
    entries: [
      {
        key: 'learning.menu.reviews',
        icon: NotebookPen,
        subPath: 'reviews',
        tab: Tabs.REVIEWS,
      },
      {
        key: 'learning.menu.rushes',
        icon: Rocket,
        subPath: `program-sessions/${C_PISCINE_PROGRAM_SESSION_ID}/rushes`,
        tab: Tabs.RUSHES,
      },
      {
        key: 'learning.menu.exams',
        icon: GraduationCap,
        tab: Tabs.EXAMS,
        subPath: `program-sessions/${C_PISCINE_PROGRAM_SESSION_ID}/exams`,
      },
    ],
  },
];

export function MenuLinks({ menu }: { menu: Menu }): React.JSX.Element {
  const { locale } = useClientLocale();
  const pathname = usePathname();
  const { t } = useClientTranslations();
  const tabs = new Set<Tabs>(menu.tabs);
  const menuToDisplay = menuEntries.filter(({ entries }) => entries.some(({ tab }) => !tab || tabs.has(tab)));

  return (
    <SidebarGroup>
      <SidebarGroupContent>
        <SidebarMenu>
          {menuToDisplay.map(({ key: menuKey, ns, path, icon: MenuIcon, entries }) => {
            const isMenuActive = pathname.startsWith(`/${locale}/${path}`);

            return (
              <Collapsible key={`${ns}.${menuKey}`} className="group/collapsible" defaultOpen={isMenuActive}>
                <SidebarMenuItem>
                  <CollapsibleTrigger asChild>
                    <SidebarMenuButton isActive={isMenuActive} data-testid={`dropdown-${menuKey}`}>
                      {MenuIcon ? <MenuIcon /> : null}
                      <TruncatedTextWithTooltip text={t(`${menuKey}`, { ns })} />
                      <ChevronRight className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-90" />
                    </SidebarMenuButton>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {entries.map(({ key: subMenuKey, subPath, icon: SubMenuIcon, tab }) => {
                        if (tab && !tabs.has(tab)) {
                          return null;
                        }
                        const completePath = `/${locale}/${path}/${subPath}`;
                        return (
                          <SidebarMenuSubItem key={`${ns}.${menuKey}.${subMenuKey}`}>
                            <SidebarMenuSubButton
                              asChild
                              isActive={pathname.startsWith(completePath)}
                              data-testid={`menu-item-id-${subMenuKey}`}
                            >
                              <Link href={completePath}>
                                {SubMenuIcon ? <SubMenuIcon /> : null}
                                <TruncatedTextWithTooltip text={t(subMenuKey, { ns })} />
                              </Link>
                            </SidebarMenuSubButton>
                          </SidebarMenuSubItem>
                        );
                      })}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                </SidebarMenuItem>
              </Collapsible>
            );
          })}
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
}
