'use client';

import { PropsWithChildren, useEffect, useState } from 'react';
import Script from 'next/script';
import { AxeptioService } from '../services/axeptio.service';
import { Configuration } from '../configuration';
import { CookiesConsentContext } from './cookies.context';

type CookiesConsents = Record<string, boolean>;

export let hasConsent: (vendor: string) => boolean = () => false;

export const CookiesConsentProvider = ({
  children,
  locale,
  configuration,
}: PropsWithChildren<{ locale: string; configuration: Configuration }>): React.JSX.Element => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [sdk, setSdk] = useState<AxeptioService | null>(null);

  const [consents, setConsents] = useState<CookiesConsents>({});
  const getConsents = (): CookiesConsents => ({ ...consents });
  hasConsent = (vendor: string): boolean => consents[vendor] ?? false;

  useEffect(() => {
    setSdk(
      new AxeptioService(locale, configuration, {
        onConsentChange: setConsents,
        onSdkReady: (): ReturnType<typeof setIsReady> => setIsReady(true),
      }),
    );
  }, [configuration, locale]);

  return (
    <CookiesConsentContext.Provider
      value={{ isReady, getConsents, hasConsent, showConsentManager: () => sdk?.showCookiesWidget() }}
    >
      {children}
      <Script src="//static.axept.io/sdk-slim.js"></Script>
    </CookiesConsentContext.Provider>
  );
};
