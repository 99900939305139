import(/* webpackMode: "eager", webpackExports: ["Background"] */ "/home/runner/_work/42next-app-client/42next-app-client/apps/admin/src/shared/components/dashboard/background.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationsProvider"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/core-systems/src/i18n/client/translations.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useClientLocale"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/core-systems/src/i18n/client/use-locale.hook.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useClientTranslations"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/core-systems/src/i18n/client/use-translation.hook.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionContent","AccordionItem","AccordionTrigger"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/accordion/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/avatar/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BackButton"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/back-button/back-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Breadcrumbs","Breadcrumb"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/breadcrumbs/breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Calendar"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/calendar/calendar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem","CarouselPrevious","CarouselNext"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/carousel/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Checkbox"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/checkbox/checkbox.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/circular-loader/circular-loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Collapsible","CollapsibleTrigger","CollapsibleContent"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/collapsible/collapsible.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Command","CommandDialog","CommandInput","CommandList","CommandEmpty","CommandGroup","CommandItem","CommandShortcut","CommandSeparator"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/command/command.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Confirmation"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/confirmation/confirmation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useDynamicConfirm"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/confirmation/use-dynamic-confirm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DatePicker"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/datepicker/datepicker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Dialog","DialogPortal","DialogOverlay","DialogClose","DialogTrigger","DialogContent","DialogHeader","DialogFooter","DialogTitle","DialogDescription"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/dialog/dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Drawer","DrawerPortal","DrawerOverlay","DrawerTrigger","DrawerClose","DrawerContent","DrawerHeader","DrawerFooter","DrawerTitle","DrawerDescription"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/drawer/drawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuItem","DropdownMenuCheckboxItem","DropdownMenuRadioItem","DropdownMenuLabel","DropdownMenuSeparator","DropdownMenuShortcut","DropdownMenuGroup","DropdownMenuPortal","DropdownMenuSub","DropdownMenuSubContent","DropdownMenuSubTrigger","DropdownMenuRadioGroup"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/dropdown-menu/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorPage"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/error-page/error-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TranslatedFileUpload"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/file-upload/file-upload.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Form","FormItem","FormLabel","FormControl","FormDescription","FormMessage","FormField","useFormField"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/form/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HorizontalStepper"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/horizontal-stepper/horizontal-stepper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Label"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/label/label.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticatedLayout"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/layout/authenticated-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/link/link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MultiSelect"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/multi-select/multi-select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PhoneInput"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/phone-input/phone-input.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Popover","PopoverTrigger","PopoverContent"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/popover/popover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FancyProgressBar"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/progress/fancy-progress.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBar"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/progress/progress.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollArea"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/scroll-area/scroll-area.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Search","CustomSearch"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/search-bar/search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Select","SelectGroup","SelectValue","SelectTrigger","SelectContent","SelectLabel","SelectItem","SelectSeparator","SelectScrollUpButton","SelectScrollDownButton"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/select/select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/separator/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetPortal","SheetOverlay","SheetTrigger","SheetClose","SheetContent","SheetHeader","SheetFooter","SheetTitle","SheetDescription"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/sheet/sheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar","SidebarContent","SidebarFooter","SidebarGroup","SidebarGroupAction","SidebarGroupContent","SidebarGroupLabel","SidebarHeader","SidebarInput","SidebarInset","SidebarMenu","SidebarMenuAction","SidebarMenuBadge","SidebarMenuButton","SidebarMenuItem","SidebarMenuSkeleton","SidebarMenuSub","SidebarMenuSubButton","SidebarMenuSubItem","SidebarProvider","SidebarRail","SidebarSeparator","SidebarTrigger","useSidebar"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/sidebar/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Stepper"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/stepper/stepper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Switch"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/switch/switch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FilterButton"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/table/filter-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/table/table.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["usePagination"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/table/use-pagination.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useSorting"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/table/use-sorting.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsList","TabsTrigger","TabsContent"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/tabs/tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextReveal"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/text-reveal/text-reveal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeSwitcher"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/theme-switcher/theme-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/toast/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useToast"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/toast/use-toast.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ToggleGroup","ToggleGroupItem"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/toggle-group/toggle-group.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toggle","toggleVariants"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/toggle/toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipTriggerInfoIcon"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/tooltip/tooltip-trigger-info-icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipTriggerText"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/tooltip/tooltip-trigger-text.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tooltip","TooltipTrigger","TooltipContent","TooltipProvider"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/tooltip/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TruncatedTextWithTooltip"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/design-system/src/components/truncated-text-with-tooltip/truncated-text-with-tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Provider","Viewport","displayName","Root","Action","Close","Title","Description"] */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/@radix-ui+react-toast@1.2.1_@types+react-dom@19.0.2_@types+react@19.0.1__@types+react@19.0.1__fidmhtxghepe6tohtzv636rpby/node_modules/@radix-ui/react-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next-intl@3.26.3_next@15.1.0_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.4_45obeeer5tdfjyv2p2nqvetfpa/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next-intl@3.26.3_next@15.1.0_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.4_45obeeer5tdfjyv2p2nqvetfpa/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.1.0_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@19._zfrnzarkr3r3qpe6l7u3yttk5y/node_modules/next/dist/client/app-dir/link.js");
