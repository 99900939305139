/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { useClientSideEnv } from '../../client-side-configuration';
import { useCookiesConsent } from '../../cookies';

export const AnalyticsProvider = (props: PropsWithChildren<{ host: string }>): React.JSX.Element => {
  const [isPosthogInit, setIsPosthogInit] = useState<boolean>(false);
  const [consentForAnalytics, setConsentForAnalytics] = useState<'uncertain' | 'denied' | 'granted'>('uncertain');
  const cookiesConsent = useCookiesConsent();
  const { analytics: configuration } = useClientSideEnv();
  const session = useSession();
  const userId = session.data?.user?.id;
  const ANALYTICS_COOKIE_NAME = 'analytics';

  /**
   * This effect is responsible for updating the consent status for analytics
   * and ensure no other useEffect is triggered until consent specific to analytics
   * changes.
   */
  useEffect(() => {
    if (!configuration.isEnabled) return;

    if (!cookiesConsent.isReady) return;

    if (!isPosthogInit) return;

    const hasConsent = cookiesConsent.hasConsent(ANALYTICS_COOKIE_NAME);

    // Scenario: consent was uncertain as the SDK was not ready
    if (consentForAnalytics === 'uncertain') {
      setConsentForAnalytics(hasConsent ? 'granted' : 'denied');
      return;
    }

    // Scenario: consent was denied and is granted now
    if (hasConsent && consentForAnalytics === 'denied') {
      setConsentForAnalytics('granted');
      return;
    }

    // Scenario: consent was granted and is denied now
    if (!hasConsent && consentForAnalytics === 'granted') {
      setConsentForAnalytics('denied');
      return;
    }

    // No change detected
  }, [cookiesConsent, isPosthogInit]);

  /**
   * This effect is responsible for toggling
   * event capturing based on the consent status.
   *
   * * NB: posthog persistence mode is set on 'memory' if no consent is given
   * * to ensure no cookie is set on the user's browser.
   */
  useEffect(() => {
    if (!isPosthogInit) return;
    if (consentForAnalytics === 'uncertain') return;

    if (consentForAnalytics === 'granted') {
      posthog.opt_in_capturing();
      posthog.set_config({ persistence: 'localStorage+cookie' });
      posthog._start_queue_if_opted_in();
      return;
    }

    if (consentForAnalytics === 'denied') {
      posthog.opt_out_capturing();
      posthog.set_config({ persistence: 'memory' });
      return;
    }
  }, [consentForAnalytics, isPosthogInit]);

  /**
   * This effect is responsible for initializing posthog.
   */
  useEffect(() => {
    if (!configuration.isEnabled) return;

    posthog.init(configuration.key, {
      api_host: `${props.host}/ingest`,
      ui_host: configuration.url,
      opt_out_capturing_by_default: true,
      persistence: consentForAnalytics === 'granted' ? 'localStorage+cookie' : 'memory',
      bootstrap: {
        distinctID: userId,
        isIdentifiedID: typeof userId === 'string',
      },
    });
    setIsPosthogInit(true);
  }, []);

  /**
   * This effect is responsible for identifying the user if a userId is provided
   * and resetting the analytics properties if the userId is removed/logged out.
   *
   * * NB: Only userId is used to identify the user, other properties should not
   * * be sent to Posthog.
   */
  const [savedUserId, setSavedUserId] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (!isPosthogInit) return;
    if (userId === savedUserId) return;

    if (userId) posthog.identify(userId);
    else posthog.reset();

    setSavedUserId(userId);
  }, [userId, isPosthogInit]);

  return <PostHogProvider client={posthog}>{props.children}</PostHogProvider>;
};
