export const slugify = (value: string): string => {
  const normalizedValue = value
    .toLocaleLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const valueWithoutSpecialCharacters = normalizedValue.replace(/[^a-z\s]/g, '');
  const valueWithoutSpaces = valueWithoutSpecialCharacters.trim().replace(/\s+/g, '-');

  return valueWithoutSpaces;
};
