'use client';

import { createContext, PropsWithChildren } from 'react';

export type PermissionsContextProps = {
  permissions: string[];
};

const defaultContextProps: PermissionsContextProps = { permissions: [] };

export const PermissionsContext = createContext<PermissionsContextProps>(defaultContextProps);

export const PermissionsProvider = ({
  children,
  permissions,
}: PropsWithChildren<PermissionsContextProps>): React.JSX.Element => {
  return <PermissionsContext.Provider value={{ permissions }}>{children}</PermissionsContext.Provider>;
};
