'use client';

import React, { useImperativeHandle, useState } from 'react';
import { VariantProps } from 'class-variance-authority';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '../drawer/drawer';
import { useIsMobile } from '../../hooks/use-mobile';
import { Button, buttonVariants } from '../button/button';
import { DialogDescription } from '../dialog/dialog';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../dialog/dialog';

export type ConfirmationProps = {
  closeLabel: string;
  confirmationLabel: string;
  title: string;
  onConfirm: () => Promise<void> | void;
  description?: React.ReactNode;
  children?: React.ReactNode;
  variant?: VariantProps<typeof buttonVariants>['variant'];
};

export interface ConfirmationRef {
  open: () => void;
  close: () => void;
}

export const Confirmation = React.forwardRef<ConfirmationRef, ConfirmationProps>(function Confirmation(
  {
    closeLabel,
    confirmationLabel,
    title,
    onConfirm,
    description,
    children,
    variant = 'destructive',
  }: ConfirmationProps,
  ref,
): React.JSX.Element {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function onClick(): Promise<void> {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  }

  useImperativeHandle(ref, () => ({
    open: (): void => setOpen(true),
    close: (): void => setOpen(false),
  }));

  return isMobile ? (
    <Drawer open={open} onOpenChange={setOpen}>
      {children ? <DrawerTrigger asChild>{children}</DrawerTrigger> : <></>}
      <DrawerContent className="gap-md flex flex-col">
        <DrawerHeader className="gap-sm flex flex-col">
          <DrawerTitle className="leading-md text-lg font-bold">{title}</DrawerTitle>
          <DrawerDescription className="text-secondary">{description}</DrawerDescription>
        </DrawerHeader>
        <DrawerFooter>
          <DrawerClose asChild>
            <Button data-testid="close-modal-button" type="button" className="w-full" variant="secondary">
              {closeLabel}
            </Button>
          </DrawerClose>
          <DrawerClose asChild>
            <Button
              data-testid="confirm-modal-button"
              onClick={onClick}
              className="w-full"
              variant={variant}
              loading={isLoading}
            >
              {confirmationLabel}
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  ) : (
    <Dialog open={open} onOpenChange={setOpen}>
      {children ? <DialogTrigger asChild>{children}</DialogTrigger> : <></>}
      <DialogContent className="gap-md flex flex-col">
        <DialogHeader className="gap-sm flex flex-col">
          <DialogTitle className="leading-md text-lg font-bold">{title}</DialogTitle>
          <DialogDescription className="text-secondary">{description}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button data-testid="close-modal-button" type="button" className="w-full" variant="secondary">
              {closeLabel}
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              data-testid="confirm-modal-button"
              onClick={onClick}
              className="w-full"
              variant={variant}
              loading={isLoading}
            >
              {confirmationLabel}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
});
