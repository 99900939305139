'use client';
import React, { useCallback, useMemo, useState } from 'react';
import { Check, ChevronDown, Square, SquareCheck, SquareMinus } from 'lucide-react';
import { cn } from '@utils';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../dropdown-menu/dropdown-menu';
import { Button, ButtonProps } from '../button/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '../command/command';

function CommandHeadingIcon<T>({
  choices,
  selection,
  onChange,
}: {
  choices: Set<T>;
  selection: Set<T>;
  onChange: (newSelection: Set<T>) => void;
}): React.JSX.Element {
  if (selection.size === choices.size) {
    return <SquareCheck className="size-md mr-xs focusable" onClick={() => onChange(new Set())} />;
  }
  if (selection.size === 0) {
    return <Square className="size-md mr-xs focusable" onClick={() => onChange(choices)} />;
  }
  return <SquareMinus className="size-md mr-xs focusable" onClick={() => onChange(choices)} />;
}

export type FilterButtonProps<T> = {
  choices: { id: T; name: string }[];
  selection: Set<T>;
  onChange: (newSelection: Set<T>) => void;
  filterLabel: React.ReactNode;
  searchLabel: string;
  emptyState: React.ReactNode;
  applyLabel: React.ReactNode;
  className?: ButtonProps['className'];
  children: React.ReactNode;
};

export function FilterButton<T>({
  choices,
  selection,
  onChange,
  filterLabel,
  searchLabel,
  emptyState,
  applyLabel,
  className,
  children,
}: FilterButtonProps<T>): React.JSX.Element {
  const choiceSet = useMemo(() => new Set(choices.map(({ id }) => id)), [choices]);

  const [open, setOpen] = useState(false);
  const [internalSelection, setInternalSelection] = useState<Set<T>>(selection);

  const onOpenChange = useCallback(
    (newOpen: boolean): void => {
      if (newOpen) {
        setInternalSelection(selection);
      } else {
        setInternalSelection(new Set());
      }
      setOpen(newOpen);
    },
    [selection],
  );

  const onSelect = (id: T): void => {
    const updatedInternalSelection = new Set(internalSelection);
    if (internalSelection.has(id)) {
      updatedInternalSelection.delete(id);
    } else {
      updatedInternalSelection.add(id);
    }
    setInternalSelection(updatedInternalSelection);
  };

  const onApply = useCallback((): void => {
    onChange(new Set(internalSelection));
    onOpenChange(false);
  }, [onChange, onOpenChange, internalSelection]);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="quaternary"
          className={cn('px-xxs -mx-xxs !py-xxs font-normal', className)}
          RightIcon={ChevronDown}
        >
          {children}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="p-md w-64">
        <Command>
          <CommandInput placeholder={searchLabel} />
          <CommandEmpty>{emptyState}</CommandEmpty>
          <CommandList>
            {choices.length > 0 && (
              <CommandGroup
                heading={
                  <div className="flex items-center">
                    <CommandHeadingIcon<T>
                      choices={choiceSet}
                      selection={internalSelection}
                      onChange={setInternalSelection}
                    />
                    {filterLabel}
                  </div>
                }
              >
                {choices.map(({ id, name }) => (
                  <CommandItem key={`command-item-${id}`} value={`${id}`} onSelect={() => onSelect(id)}>
                    <Check className={cn('mr-xs size-md', internalSelection.has(id) ? '' : 'invisible')} />
                    {name}
                  </CommandItem>
                ))}
              </CommandGroup>
            )}
          </CommandList>
        </Command>
        {choices.length > 0 && (
          <div className="mt-sm flex w-full justify-center">
            <Button variant="tertiary" onClick={onApply} disabled={internalSelection.size === 0}>
              {applyLabel}
            </Button>
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
