'use client';
import { useEffect } from 'react';
import { AuthAction, uriToAuth } from '../utils';

export type RedirectToLoginProps = {
  locale?: string;
  authAppUrl: string;
  isLoginWithGoogleEnabled?: boolean;
};

export function RedirectToLogin({
  locale,
  authAppUrl,
  isLoginWithGoogleEnabled = false,
}: RedirectToLoginProps): React.JSX.Element {
  useEffect(() => {
    const currentUri = window.location.href;
    window.location.href = uriToAuth({
      action: AuthAction.Login,
      authAppUrl,
      redirectUri: currentUri,
      locale,
      isLoginWithGoogleEnabled,
    });
  }, [locale, authAppUrl, isLoginWithGoogleEnabled]);
  return <></>;
}
