'use client';

import { useEffect, useState } from 'react';

export const Background = (): React.JSX.Element => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setIsReady(true);
  }, []);

  if (isReady) {
    return (
      <div className="animate-fade absolute bottom-0 left-0 -z-10 h-full w-full bg-[url('/assets/images/bg_welcomepage.svg')] bg-[length:100%_auto] bg-bottom bg-no-repeat" />
    );
  }

  return <></>;
};
