import * as z from 'zod';

export const i18nConfigSchema = z.object({
  languages: z.array(z.object({ key: z.string().min(1), name: z.string().min(1), isDefault: z.boolean() })).min(1),
  cdnUri: z.string().optional(),
  namespaces: z.array(z.string().min(1)).min(1),
});

export type Configuration = z.infer<typeof i18nConfigSchema>;

const getI18nConfiguration = (): typeof configuration => {
  const configuration = {
    cdnUri: process.env.I18N_CDN_URI,
    languages: JSON.parse(process.env.I18N_LANGUAGES || '[]'),
    namespaces: process.env.I18N_NAMESPACES?.split(',') || [],
    cookie: {
      name: 'lang',
      options: {
        domain: `.${process.env.SAFE_DOMAIN}`,
        maxAge: 365 * 24 * 60 * 60 * 1000,
        httpOnly: process.env.NODE_ENV !== 'development',
        secure: true,
        sameSite: 'strict' as 'lax' | 'strict' | 'none',
      },
    },
  };

  return configuration;
};
export default getI18nConfiguration;
