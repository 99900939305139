'use client';

import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';

import { cn } from '@utils';
import { Check } from 'lucide-react';
import { HTMLAttributes } from 'react';

function createBadges(completedSteps: number, stepsCount: number, badgeClassName?: string): React.JSX.Element[] {
  const badges = [];
  for (let index = 0; index < stepsCount; index++) {
    const success = index < completedSteps;
    badges.push(
      <div
        key={index}
        className={cn(
          `size-lg bg-background z-10 flex items-center justify-center rounded-full`,
          `border ${success ? 'border-success' : 'border-primary-border'}`,
          badgeClassName,
        )}
      >
        {success && <Check className="size-md text-success font-bold" />}
      </div>,
    );
  }
  return badges;
}

export const HorizontalStepper = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
    completedSteps: number;
    stepsCount: number;
    badgeClassName?: HTMLAttributes<HTMLDivElement>['className'];
  }
>(({ className, completedSteps, stepsCount, badgeClassName, ...props }, ref) => {
  if (stepsCount === 1) {
    return createBadges(completedSteps, 1, badgeClassName);
  }
  if (completedSteps > stepsCount) {
    throw new Error('you cannot complete more than the number of steps !');
  }
  const ceilCurrentValue = Math.min(completedSteps, stepsCount);
  const completionPercentage = Math.round((ceilCurrentValue / (stepsCount - 1)) * 100);

  return (
    <div className="relative w-full">
      <div className="absolute left-0 right-0 top-1/2 w-full overflow-hidden">
        <ProgressPrimitive.Root
          ref={ref}
          className={cn('bg-secondary-separator relative h-[1px] w-full overflow-hidden rounded-full', className)}
          {...props}
        >
          <ProgressPrimitive.Indicator
            className={`bg-success h-full w-full flex-1 transition-all`}
            style={{ transform: `translateX(-${100 - (completionPercentage || 0)}%)` }}
          />
        </ProgressPrimitive.Root>
      </div>
      <div className="flex w-full flex-row justify-between">
        {createBadges(completedSteps, stepsCount, badgeClassName)}
      </div>
    </div>
  );
});
HorizontalStepper.displayName = ProgressPrimitive.Root.displayName;
